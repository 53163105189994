<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row>
          <a-col :md="8" :sm="24">
            <a-form-item
              label="行程日期"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-range-picker v-model="queryParams.queryDate" />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item
              label="员工"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.username" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item
              label="部门"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-input v-model="queryParams.deptName" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator confirm">
        <a-select
          show-search
          placeholder="请选择审批单"
          v-model="batchId"
          style="width: 20%; margin-right: 5px"
        >
          <a-select-option
            v-for="b in toSubmitBatchList"
            :key="b.batchId"
            :value="b.batchId"
            >{{ b.batchNo }}</a-select-option
          >
        </a-select>
        <a-button @click="batchConform" type="primary">加入审批单</a-button>
        <a-button @click="batchInvalid" type="danger">作废</a-button>
        <a-button
          v-hasPermission="['project:add']"
          type="primary"
          ghost
          @click="exportExcel"
          >导出Excel</a-button
        >
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.allowanceId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        @change="handleTableChange"
        bordered
        :scroll="{ x: 900 }"
      >
        <template slot="operation" slot-scope="text, record">
          <a-icon
            type="setting"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改"
          ></a-icon>
        </template>
      </a-table>
      <allowance-confirm-edit
        ref="allowanceConfirmEdit"
        @success="handleSuccess"
      >
      </allowance-confirm-edit>
    </div>
  </a-card>
</template>
<script>
import RangeDate from "@/components/datetime/RangeDate";
import { mapState } from "vuex";
import allowanceConfirmEdit from "./allowanceConfirmEdit";
import moment from "moment";

export default {
  name: "allowanceConfirm",
  components: { RangeDate, allowanceConfirmEdit },
  data() {
    return {
      fileList: [],
      importData: [],
      times: "",
      errors: [],
      uploading: false,
      importResultVisible: false,
      advanced: false,
      dataSource: [],
      selectedRowKeys: [],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },
      queryParams: {
        queryDate: [
          moment(new Date(), "YYYY-MM-DD").subtract(30, "day"),
          moment(new Date(), "YYYY-MM-DD"),
        ],
        username: "",
        deptName: "",
      },
      projectAddVisiable: false,
      projectEditVisiable: false,
      batchId: undefined,
      toSubmitBatchList: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.user_allowance_status,
      taskType: (state) => state.dict.dicts.task_task_type,
    }),
    columns() {
      return [
        {
          title: "员工编号",
          fixed: "left",
          dataIndex: "empno",
          width: 100,
        },
        {
          title: "用户名",
          fixed: "left",
          dataIndex: "username",
          width: 100,
        },
        {
          title: "部门",
          fixed: "left",
          dataIndex: "deptName",
          width: 150,
        },
        {
          title: "服务台数",
          fixed: "left",
          dataIndex: "totalServeNumbers",
          width: 150,
        },
        {
          title: "行程开始",
          dataIndex: "beginDate",
          width: 150,
          customRender: (text, row, index) => {
            return (
              moment(text).format("YYYY-MM-DD") + " " + row.beginHour + "时"
            );
          },
        },
        {
          title: "行程结束",
          dataIndex: "endDate",
          width: 150,
          customRender: (text, row, index) => {
            return moment(text).format("YYYY-MM-DD") + " " + row.endHour + "时";
          },
        },
        {
          title: "天数",
          dataIndex: "days",
          width: 80,
        },
        {
          title: "工单",
          dataIndex: "taskNo",
          width: 150,
        },
        {
          title: "合同号",
          dataIndex: "contractNo",
          width: 150,
        },
        {
          title: "客户名称",
          dataIndex: "customer",
          width: 150,
        },
        {
          title: "服务类型",
          dataIndex: "taskType",
          width: 150,
          customRender: (text, row, index) => {
            for (let _index in this.taskType) {
              let key = this.taskType[_index].keyy;
              if (text === key) {
                let val = this.taskType[_index].valuee;
                let color = this.taskType[_index].otherKeyy;
                return <a-badge color={color} text={val}></a-badge>;
              } else {
                continue;
              }
            }
            return taskType;
          },
        },
        // {
        //   title: "早餐补贴",
        //   dataIndex: "breakfastFee",
        //   width: 100,
        // },
        // {
        //   title: "午餐补贴",
        //   dataIndex: "lunchFee",
        //   width: 100,
        // },
        // {
        //   title: "晚餐补贴",
        //   dataIndex: "supperFee",
        //   width: 100,
        // },
        // {
        //   title: "住宿补贴",
        //   dataIndex: "hotelFee",
        //   width: 100,
        // },
        {
          title: "餐补",
          dataIndex: "mealFee",
          width: 100,
        },
        {
          title: "交通费用",
          dataIndex: "carFee",
          width: 100,
        },
        {
          title: "特殊补贴",
          dataIndex: "specialFee",
          width: 100,
        },
        {
          title: "其他费用",
          dataIndex: "otherFee",
          width: 100,
        },
        {
          title: "差旅补贴合计",
          dataIndex: "workFee",
          width: 100,
        },
        {
          title: "状态",
          fixed: "right",
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (text === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 100,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          width: 100,
        },
      ];
    },
  },
  mounted() {
    this.fetch();
    this.getToSubmitBatchList();
  },
  methods: {
    moment,
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.paginationInfo = pagination;
      this.fetch({
        ...this.queryParams,
      });
    },
    // 获取待提交的审批单
    getToSubmitBatchList() {
      this.$get("approve-batch/list-to-submit", {}).then((r) => {
        this.toSubmitBatchList = r.data.data;
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("allowance/page-un-submit", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    selectData(params = {}) {
      this.loading = true;
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize =
        this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("allowance/page-un-submit", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
    exportExcel() {
      let params = this.queryParams;
      params.beginDateFrom = this.queryParams.queryDate[0].format("YYYY-MM-DD");
      params.endDateTo = this.queryParams.queryDate[1].format("YYYY-MM-DD");
      delete params.queryDate;
      let allowanceIds = "";
      if (this.selectedRowKeys.length) {
        allowanceIds = this.selectedRowKeys.join(",");
      }
      this.$export("allowance/excel?allowanceIds=" + allowanceIds, {
        ...params,
      });
    },
    batchConform() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要确认的记录");
        return;
      }
      if (!this.batchId) {
        this.$message.warning("请选择审批单");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定将所选记录加入审批单吗?",
        content: "当您点击确定按钮后，这些记录将进入审批单中",
        centered: true,
        onOk() {
          let allowanceIds = that.selectedRowKeys.join(",");
          let batchId = that.batchId;
          that
            .$put("allowance/confirm/" + allowanceIds + "/" + batchId)
            .then(() => {
              that.$message.success("确认成功");
              that.selectedRowKeys = [];
              that.search();
            });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    batchInvalid() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要作废的记录");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定作废这些记录吗?",
        content: "当您点击作确定钮后，这些记录将进入已作废中",
        centered: true,
        onOk() {
          let allowanceIds = that.selectedRowKeys.join(",");
          that.$put("allowance/invalid/" + allowanceIds).then(() => {
            that.$message.success("作废成功");
            that.selectedRowKeys = [];
            that.search();
          });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    edit(record) {
      this.$refs.allowanceConfirmEdit.setForm(record);
    },
    handleSuccess() {
      this.fetch();
    },
    reset() {
      // 重置查询参数
      this.queryParams.username = "";
      this.queryParams.deptName = "";
      this.fetch();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../utils/Common.less";
.confirm {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>